import { ViewType } from '@/modules/common/types';
import { SVGProps } from 'react';
import { Nullable } from '../../../../modules/common/types';

export interface OrderView {
  isBaseView: boolean;
  isDefault: boolean;
  isEditable: boolean;
  name: string;
  orderId: number;
  viewId: number;
  viewType: ViewType;
  linkedView: number;
  isSIAssignmentLocked: boolean;
  isPricingLocked: boolean,
}

export enum ViewCategory {
  BLANK_DRAFT = 'Blank Draft View',
  TAKEOFF_DRAFT = 'Takeoff Draft View',
  ESTIMATION = 'Estimation View',
  AUDIT = 'Audit View',
}

export interface View {
  title: ViewCategory;
  desc: string;
  image: SVGProps<any>;
}

export enum OrderTimelineStep {
  AddressConfirmation = 0,
  ParcelConfirmation = 1,
  CustomerInformation = 2,
  FeatureSelection = 3,
  Measurements = 4,
  AwaitingApproval = 5,
  FeedbacksApproved = 6,
  PostMeasurement = 7,
}

export enum EstimationTabs {
  SERVICE_MAP = '1',
  ESTIMATION = '2',
  BREAKDOWN = '3',
  INVOICE = '4',
}

export enum ParcelAttachmentType {
  IMAGE_JPG = 'image/jpg',
  IMAGE_PNG = 'image/png',
  PDF = 'pdf',
}

export interface ParcelAttachments {
  url: string;
  name: string;
  type: ParcelAttachmentType;
  thumbnail: Nullable<string>;
  duration: Nullable<string>;
}

export interface MeasurementPreferences {
  followParcelStrictly: boolean;
  includeCommonArea: boolean;
  includeHouseArea: boolean;
  isHOAMeasurement: boolean;
  separateMeasurementForCommonAndHousingArea: boolean;
}