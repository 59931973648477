import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Text from '@elements/text/Text';
import './styles.less';
import EmViewPanel from '../../../../PlasmicComponents/EmViewPanel';
import CreateNewView from '../ViewAction/CreateNewView';
import { showNotification } from '../../../../storybook/NotificationToast/NotificationToast';
import { EstimationTabs, OrderView, ViewCategory } from '../types';
import { NOTIFICATIONS_TYPES } from '../../../../storybook/NotificationToast/types';
import { replaceParams } from '../../../../../helpers/utilities/linkUtils';
import {
  getAxiosInstance,
  catchError,
} from '../../../../../helpers/utilities/api-utils';
import { trackEvents } from '../../../../../helpers/utilities';
import useRouterPlus from '../../../../../modules/router-plus/hooks/use-router-plus';
import { isValidViewId } from '@project/projectComponents/helpers';
import { useDispatch } from 'react-redux';
import { updateViewsByOrderId } from '@/store/order/thunks';
import { NotifyError } from '@/helpers/notification-utils';
import { Button, Modal, Row } from 'antd';
import AddViewGuard from '../../../../../modules/subscription/guards/project/AddViewGuard';
import ActionModal from '@elements/ActionModal';
import question from '@/assets/Question-mark.svg';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { OrderStatus, ViewType } from '@/modules/common/types';
import useRouterPlusRedirect from '../../../../../modules/router-plus/hooks/use-router-plus-redirect';
import { saveIfAnyUnSyncedModifications } from '../../../../containers/map-tools-panel/stories/Toolbar/MapToolBar/helpers';
import { useSelector } from 'react-redux';
import { ViewSelectionPanelItem } from './types';
import { useElementPositioningContext } from '../../../../../Contexts/ElementPositioningContext';
import { WidgetBarTabs } from '../../../../types';
import { useWidgetBarTabContext } from '../../../../../modules/property/hooks/useWidgetBar';
import { extractNextViewName, vhMinusPx } from '../helpers';
import useViewSelectionOrCreationUtil from '../../../../../modules/property/hooks/useViewSelectionOrCreationUtil';
import { useEstimationViewContext } from '../../../../../modules/property/context/EstimationView/context';
import { deactivateEstimationSheet } from './helper'
import { getPlasmicOrderStatus } from '../../utils/helpers';
import { CreateOrderEvt } from '../../../../../segment';
import { useRole } from '@/modules/auth/guards/RoleGuard';
import EodTakeoffEstimationNewViewCreation from '../../../../PlasmicComponents/EodTakeoffEstimationNewViewCreation';
import { setOrderViewModal } from '../../../../../store/order/actions';

interface ViewSelectionPanelProps {
  isOpen: boolean;
  onClose: () => void;
  createNewView: (viewRes: any, isSelectedBaseView: boolean) => void;
  onChange: (view: OrderView) => void;
  onDelete: (view: OrderView) => void;
  orderViews: Array<OrderView>;
  setDataLoading: (arg: boolean) => void;
  currentView: OrderView;
  showAddNewViewAlertModal: boolean;
  setShowAddNewViewAlertModal: (arg: boolean) => void;
  orderStatus: OrderStatus;
  reloadViews: () => void;
  toggleViewpanelPin: (val: Boolean) => void;
  isViewpanelPinned: boolean;
  openFPanel: boolean;
  isShareLinkPage: boolean;
  isEstimationView: boolean;
}

const ViewSelectionPanel: React.FC<ViewSelectionPanelProps> = ({
  isOpen,
  onClose,
  createNewView,
  orderViews,
  setDataLoading,
  currentView,
  onChange,
  showAddNewViewAlertModal,
  setShowAddNewViewAlertModal,
  onDelete,
  orderStatus,
  reloadViews,
  toggleViewpanelPin,
  isViewpanelPinned,
  openFPanel,
  isShareLinkPage,
  isEstimationView,
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const orderViewsRedux = useSelector((state) => state.order.orderViews);
  const currentUserName = useSelector((state) => state.user.info.first_name);
  const viewSelectionModal = useSelector((state) => state.order.showOrderViewsModal);
  const [viewsToRender, setViewsToRender] = useState<ViewSelectionPanelItem[]>(
    []
  );

  useEffect(() => {
    const viewsToRender = convertViews(orderViewsRedux);
    setViewsToRender(viewsToRender);
  }, [orderViewsRedux]);

  const [showCopyViewModal, setShowCopyViewModal] = useState(false);
  const [showCreateNewViewModal, setShowCreateNewViewModal] = useState(false);
  const [newCopiedViewName, setNewCopiedViewName] = useState('');
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [selectedViewForClone, setSelectedViewForClone] = useState<
    number | undefined
  >();
  const [showDeleteViewModal, setShowDeleteViewModal] = useState(false);
  const [toBeDeletedView, setToBeDeletedView] = useState<OrderView | null>(
    null
  );

  const { role: userRole } = useRole();

  const { setIsViewPinned, setNormalLayerPanelOpen } =
    useElementPositioningContext();
  const { handleActiveWidgetBarTabs, activeTabs, handleWidgetBarTabChange, selectedTab } = useWidgetBarTabContext();

  const { getLinkedStaticView } = useViewSelectionOrCreationUtil();

  const { setData: setServiceItemsData } = useEstimationViewContext();

  const axiosInstance = getAxiosInstance();
  const dispatch = useDispatch();

  const getSelectedViewForClone = useCallback((): number | undefined => {
    if (currentView.viewType === ViewType.STATIC) {
      return currentView.viewId;
    }

    return orderViews.find((view) => view.viewType === ViewType.STATIC)?.viewId;
  }, [currentView, orderViews]);

  useEffect(() => {
    if (openFPanel) {
      setNormalLayerPanelOpen(currentView.viewType === ViewType.STATIC);
    }
  }, [openFPanel]);

  const convertViews = (data) => {
    const outputViews = [];
    const viewMap = {};

    for (const view of data) {
      const {
        isBaseView,
        isDefault,
        isEditable,
        lastFetchedTime,
        linkedView,
        name,
        orderId,
        viewId,
        viewType,
        isSIAssignmentLocked,
        isPricingLocked,
        siLockDetails,
      } = view;

      if (viewType === ViewType.STATIC) {
        const outputView = {
          viewId,
          isBaseView,
          isDefault: isDefault,
          viewName: name,
          linkedViews: [],
          isSIAssignmentLocked,
          isPricingLocked,
        };
        outputViews.push(outputView);
        viewMap[viewId] = outputView;
      } else if (
        [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(viewType) &&
        linkedView !== null
      ) {
        const parentView = viewMap[linkedView];
        if (parentView) {
          parentView.linkedViews.push({
            viewId,
            viewName: name,
            isSIAssignmentLocked,
            isPricingLocked,
            siLockDetails,
            isLockedByUser: siLockDetails?.lockedBy === currentUserName,
          });
        }
      }
    }

    return outputViews;
  };

  useEffect(() => {
    if (isOpen) {
      handleActiveWidgetBarTabs([WidgetBarTabs.VIEWS, ...(activeTabs || [])]);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsViewPinned(isViewpanelPinned);
  }, [isViewpanelPinned]);

  const { redirect } = useRouterPlus(
    'viewId',
    {
      onChange: (viewId, prevViewId) => {
        console.log('CURRENT_VIEW: ', viewId);
        if (
          !viewId ||
          !isValidViewId(viewId) ||
          !orderViews.find((view) => view.viewId !== Number(viewId))
        ) {
          /** If viewId is null or undefined, it means url does not have any viewId yet.
           *  We have to add currentSelectedView (default) to the url
           **/
          if (currentView.viewId) {
            redirect(String(currentView.viewId), true);
          }

          return;
        }

        if (currentView.viewId && currentView.viewId === Number(viewId)) {
          return;
        }

        const view = orderViews.find((view) => view.viewId === Number(viewId));
        if (view) onChange(view);
      },
    },
    [orderViews?.length, currentView.viewId]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        nodeRef.current &&
        nodeRef.current instanceof Node &&
        !nodeRef.current.contains(event.target as Node) &&
        /** Pass the classname of element on click of which you dont want the view selection panel to close */
        !(event.target as HTMLElement).classList.contains('ant-dropdown-menu-item') &&
        !(event.target as HTMLElement).classList.contains('vp-kb-menu-item') &&
        !isViewpanelPinned
      ) {
        onClose();
        if (activeTabs?.includes(WidgetBarTabs.VIEWS)) {
          // pass all tabs exceps views
          handleActiveWidgetBarTabs(
            activeTabs.filter((tab) => tab !== WidgetBarTabs.VIEWS)
          );
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const { redirect: redirectPlus } = useRouterPlusRedirect();

  useEffect(() => {
    setNewCopiedViewName(checkForDraftName(orderViews));
    setSelectedViewForClone(getSelectedViewForClone());
    dispatch(setOrderViewModal(showAddNewViewAlertModal))
    setShowCreateNewViewModal(showAddNewViewAlertModal);
  }, [
    currentView?.viewId,
    currentView?.viewType,
    showAddNewViewAlertModal,
    orderStatus,
  ]);

  const handleViewClick = async (view: OrderView) => {
    if ((view.viewId !== currentView.viewId) && ([ViewType.ESTIMATION, ViewType.DYNAMIC].includes(view.viewType))) setServiceItemsData(null);

    if (saveIfAnyUnSyncedModifications()) {
      /** If any unsyced modifications are there, we need to wait for some time to sync them before changing the view */
      await new Promise((r) => setTimeout(r, 1000));
    }

    redirect(String(view.viewId), true);
    redirectPlus(
      { viewId: String(view.viewId) },
      {
        keepQueryParams: true,
        removeQueryParams: ['n', 'vId', 'fb', 'oid', 'sr'],
        mode: 'search',
      }
    );
  };

  const setDataLoadingHandler = (arg) => {
    setIsDataLoading(arg);
    setDataLoading(arg);
  };

  const handleSetDefaultView = (view) => {
    let reqData = { isDefault: true };
    setDataLoading(true);
    trackEvents(CreateOrderEvt.ViewPanelViewSetAsDefault, {
      orderId: view.orderId,
      viewId: view.viewId,
      isBaseView: view.isBaseView,
    });
    axiosInstance
      .put(
        replaceParams(API_ENDPOINTS.ORDER_VIEW_DETAILS, {
          ':orderId': view.orderId,
          ':viewId': view.viewId,
        }),
        reqData
      )
      .then((res) => {
        reloadViews();
      })
      .catch(catchError);
  };

  const handleCreateNewView = (viewCatergory?: ViewCategory, generateViewName?: boolean) => {

    let generatedViewName = newCopiedViewName; // Default to the current state value
    let finalSelectedViewForClone = selectedViewForClone;

    console.log("wkp_data_1", generatedViewName)
    // Generate a new view name if needed also update finalSelectedViewForClone 
    // since weare directly creating view without selecting base view from modal from the current view
    if (generateViewName) {
      generatedViewName = extractNextViewName(orderViews, viewCatergory); // Generate the new view name
      console.log("wkp_data_2", generatedViewName)
      setNewCopiedViewName(generatedViewName); // Update the state (optional if you only use the local variable)
      if (viewCatergory === ViewCategory.ESTIMATION && currentView.viewId) {
        finalSelectedViewForClone = currentView.linkedView
      }
    }

    // Use the local variable `generatedViewName` for further logic
    if (!generatedViewName || generatedViewName.length === 0) {
      showNotification(NOTIFICATIONS_TYPES.WARNING, 'Please enter View Name');
      return;
    }
    let reqData = {
      name: generatedViewName,
    };

    console.log("wkp_data_3", newCopiedViewName)

    setDataLoadingHandler(true);
    let isSelectedBaseView = false;
    let items = orderViews
      .filter((e) => e.viewId === finalSelectedViewForClone)
      .map((x) => x.isBaseView);
    if (items) {
      isSelectedBaseView = items[0];
    }

    console.log("wkp_data_4", viewCatergory)

    let url: string;
    if (viewCatergory === ViewCategory.ESTIMATION) {
      url = API_ENDPOINTS.CLONE_ESTIMATION_VIEW;
      setServiceItemsData(null);
    } else {
      url = API_ENDPOINTS.CLONE_VIEW;
    }

    console.log("wkp_data_5", url)

    console.log("wkp_data_6", finalSelectedViewForClone)

    // return;

    axiosInstance
      .post<OrderView>(
        replaceParams(url, {
          ':viewId': finalSelectedViewForClone,
        }),
        reqData,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      .then(async (res) => {

        trackEvents(CreateOrderEvt.ViewPanelNewView, {
          linkedView: finalSelectedViewForClone,
          viewType: viewCatergory === ViewCategory.ESTIMATION ? 'estimation' : 'static',
        })

        // orderViews.push(res.data);
        if (res.data.viewId) {
          redirect(String(res.data.viewId));
        }
        setDataLoadingHandler(false);
        setShowCreateNewViewModal(false);
        setShowCopyViewModal(false);
        setShowAddNewViewAlertModal(false);
        setNewCopiedViewName('');
        await dispatch(updateViewsByOrderId(currentView.orderId));

        createNewView(res.data, isSelectedBaseView);
      })
      .catch((error) => {
        if (error.errorCode === 'SR-BIZ-ERROR-22') {
          NotifyError(error.errorDesc);
        }
        setDataLoadingHandler(false);
        setShowCreateNewViewModal(false);
        setShowCopyViewModal(false);
        setShowAddNewViewAlertModal(false);
      });
  };

  const handleDeleteView = (view: OrderView) => {
    setShowDeleteViewModal(true);
    setToBeDeletedView(view);
  };

  const performEditViewName = (view: OrderView, newViewName: string) => {
    let reqData = {
      name: newViewName,
    };
    trackEvents(CreateOrderEvt.ViewPanelRenameView, {
      orderId: view.orderId,
      viewId: view.viewId,
      isBaseView: view.isBaseView,
      oldName: view.name,
      newName: newViewName,
    });
    setDataLoadingHandler(true);
    axiosInstance
      .put(
        replaceParams(API_ENDPOINTS.ORDER_VIEW_DETAILS, {
          ':orderId': view.orderId,
          ':viewId': view.viewId,
        }),
        reqData
      )
      .then((res) => {
        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          'Information updated successfully'
        );
        reloadViews();
        setDataLoadingHandler(false);
      })
      .catch(catchError);
  };

  const performDeleteView = () => {
    setDataLoadingHandler(true);
    trackEvents(CreateOrderEvt.ViewPanelDeleteView, {
      orderId: toBeDeletedView?.orderId,
      viewId: toBeDeletedView?.viewId,
      isBaseView: toBeDeletedView?.isBaseView,
    });

    axiosInstance
      .delete(
        replaceParams(API_ENDPOINTS.ORDER_VIEW_DETAILS, {
          ':orderId': toBeDeletedView?.orderId,
          ':viewId': toBeDeletedView?.viewId,
        })
      )
      .then(async (res) => {  // Make the callback async
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Deleted successfully');
        toBeDeletedView && reloadViews();
        setDataLoadingHandler(false);
        dispatch(updateViewsByOrderId(toBeDeletedView?.orderId));

        if (!toBeDeletedView) return;

        if (toBeDeletedView?.viewId === currentView.viewId) {
          const toBeDeletedViewType = toBeDeletedView?.viewType;

          if ([ViewType.DYNAMIC, ViewType.ESTIMATION].includes(toBeDeletedViewType)) {
            const linkedView = getLinkedStaticView(toBeDeletedView);

            if (!linkedView) return;

            onChange(linkedView);
            redirect(String(linkedView.viewId));
          } else {
            const defaultView = orderViews.find((view) => view.isDefault);

            if (!defaultView) return;

            onChange(defaultView);
            redirect(String(defaultView.viewId));
          }
        }

        // Call deactivateEstimationSheet API 
        if (toBeDeletedView?.viewType === ViewType.ESTIMATION) {
          try {
            await deactivateEstimationSheet(toBeDeletedView?.viewId, toBeDeletedView?.orderId);
          } catch (error) {
            console.error('Error fetching service items:', error);
          }
        }
      })
      .catch(() => {
        setDataLoadingHandler(false);
      });

    setShowDeleteViewModal(false);
    setToBeDeletedView(null);
  };


  const deleteViewModal = (
    <ActionModal
      isVisible={showDeleteViewModal}
      setModalVisible={setShowDeleteViewModal}
      title={'Confirmation'}
      icon={question}
      content={
        <p>
          Deleting <strong>"{toBeDeletedView?.name}"</strong> will delete all
          the notes, tags, and other elements associated to this view of the
          property. Do you want to delete this view ?
        </p>
      }
      onOK={performDeleteView}
      loading={isDataLoading}
    />
  );

  const checkForDraftName = (viewList: Array<OrderView>) => {
    let draftCount = 1;
    if (viewList.length) {
      viewList.forEach((val, index) => {
        if (
          val.name.substring(0, 5) === 'Draft' ||
          val.name.substring(0, 5) === 'draft'
        ) {
          draftCount = draftCount + 1;
        }
      });
    }

    return `Draft ${draftCount}`;
  };

  // const createNewViewModal = (
  //   <Modal
  //     visible={showCreateNewViewModal}
  //     onCancel={() => {
  //       setShowCreateNewViewModal(false);
  //       setShowAddNewViewAlertModal(false);
  //     }}
  //     footer={null}
  //     closable={false}
  //     maskClosable
  //   >
  //     <Row className={'mx-4 my-4'} align={'middle'} justify={'center'}>
  //       <Row align={'middle'} justify={'center'}>
  //         <h1 style={{ textAlign: 'center', fontSize: '20px' }}>
  //           Creating a New Draft View for Editing
  //         </h1>
  //         <Text type={'p24'}>
  //           <p style={{ textAlign: 'center' }} className={'pt-4'}>
  //             Property map can't be edited in <b>"{currentView.name}"</b>, so we
  //             are creating a Takeoff Draft View for editing. This will keep the
  //             original data intact. yy
  //           </p>
  //         </Text>
  //       </Row>
  //       <Row className={'mt-5'} align={'middle'} justify={'center'}>
  //         <Button
  //           type='default'
  //           className={'mx-4'}
  //           onClick={() => {
  //             setShowCreateNewViewModal(false);
  //             setShowAddNewViewAlertModal(false);
  //           }}
  //         >
  //           Cancel
  //         </Button>
  //         <AddViewGuard>
  //           <Button
  //             type='primary'
  //             className={'mx-4'}
  //             onClick={() => {
  //               handleCreateNewView();
  //             }}
  //           >
  //             Confirm
  //           </Button>
  //         </AddViewGuard>
  //       </Row>
  //     </Row>
  //   </Modal>
  // );

  const getOrderViewsList = () => {
    let newOrderViews = [];
    orderViews.map(view => {
      const isLockedByUser = view?.siLockDetails?.lockedBy === currentUserName;
      view.isLockedByUser = isLockedByUser;
      newOrderViews.push(view);
    })
    return newOrderViews;
  }

  const currentViewData = () => {
    return { ...currentView, isLockedByUser: currentView?.siLockDetails?.lockedBy === currentUserName };
  }

  const createNewViewModal = () => {
    const { viewType, linkedView, viewId } = currentView;
    const isTakeOffView = viewType === 'static';
    if (viewSelectionModal) {
      return <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 900 /* Ensure it is on top */,
      }}>
        <EodTakeoffEstimationNewViewCreation
          onCancel={() => {
            dispatch(setOrderViewModal(false))
            setShowCreateNewViewModal(false);
            setShowAddNewViewAlertModal(false);
          }}
          onCreateCopy={() => {
            let viewCategory = ViewCategory.TAKEOFF_DRAFT;
            if (currentView.viewType === 'estimation' || currentView.viewType === 'dynamic') {
              viewCategory = ViewCategory.ESTIMATION
            }
            handleCreateNewView(viewCategory, true);
          }}
          currentView={currentViewData()}
          isTakeoff={isTakeOffView}
          linkedView={linkedView}
          viewsData={getOrderViewsList()}
          onCreateNewView={() => {
            let viewCategory = ViewCategory.TAKEOFF_DRAFT;
            if (currentView.viewType === 'estimation' || currentView.viewType === 'dynamic') {
              viewCategory = ViewCategory.ESTIMATION
            }
            handleCreateNewView(viewCategory, true);
          }}
          onSwitchToExistingView={(data) => {
            handleViewSelectionClick(data.viewId)
            // redirect(String(data.viewId));
            // redirectPlus(
            //   { viewId: String(data.viewId) },
            //   {
            //     keepQueryParams: true,
            //     removeQueryParams: ['n', 'vId', 'fb', 'oid', 'sr'],
            //     mode: 'search',
            //   }
            // );
            setDataLoadingHandler(false);
            setShowCreateNewViewModal(false);
            setShowCopyViewModal(false);
            setShowAddNewViewAlertModal(false);
            setNewCopiedViewName('');
          }}
        />
      </div>
    }
  }

  const copyViewModal = (
    <CreateNewView
      isDataLoading={isDataLoading}
      orderViews={orderViewsRedux}
      currentView={currentView}
      visiblity={showCopyViewModal}
      setVisibility={setShowCopyViewModal}
      handleCreateNewView={handleCreateNewView}
      selectedViewForClone={selectedViewForClone}
      setSelectedViewForClone={setSelectedViewForClone}
      newCopiedViewName={newCopiedViewName}
      setNewCopiedViewName={setNewCopiedViewName}
    />
  );

  const handleViewSelectionClick = (viewId: number) => {
    const selectedView = orderViews.find(
      (view) => view.viewId === viewId
    );

    trackEvents(CreateOrderEvt.ViewPanelViewChange, {
      orderId: selectedView?.orderId,
      viewId: viewId,
      isBaseView: selectedView?.isBaseView,
    });

    if (selectedView) {
      if (selectedTab === WidgetBarTabs.ESTIMATION) {
        if (selectedView.viewType === ViewType.ESTIMATION) {
          // handleStaticViewClick();
          handleWidgetBarTabChange(WidgetBarTabs.ESTIMATION, selectedTab);
        } else {
          handleWidgetBarTabChange(WidgetBarTabs.LAYERS, selectedTab);
        }
      }

      handleViewClick(selectedView);
    }
    if (!isViewpanelPinned) onClose();
  }

  return createPortal(
    <>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames='view-selection-menu'
        unmountOnExit
      >
        <div
          className='view-selection-menu'
          ref={nodeRef}
          style={{ border: isViewpanelPinned ? '0.5px solid #DDD' : 'none' }}
        >
          <div className='view-selection-menu-content h-100'>
            <EmViewPanel
              isEstimationWorkspace={isEstimationView}
              isSharedLinkPage={isShareLinkPage}
              viewPanelHeight={vhMinusPx(100, 98)}
              viewList2={
                viewsToRender.map((item) => ({
                  viewId: item.viewId,
                  isBaseView: item.isBaseView,
                  isDefault: item.isDefault,
                  viewName: item.viewName,
                  linkedViews: item.linkedViews,
                  isLockedByUser: item.isLockedByUser,
                })) as ViewSelectionPanelItem[]
              }
              onCreateNewView={() => setShowCopyViewModal(true)}
              onPinViewPanel={() => {
                if (isViewpanelPinned) {
                  trackEvents(CreateOrderEvt.ViewPanelViewUnpinned)
                }
                else {
                  trackEvents(CreateOrderEvt.ViewPanelViewPinned)
                }
                toggleViewpanelPin(!isViewpanelPinned);

                if (isViewpanelPinned) {
                  onClose();
                  if (activeTabs?.includes(WidgetBarTabs.VIEWS)) {
                    handleActiveWidgetBarTabs(
                      activeTabs.filter((tab) => tab !== WidgetBarTabs.VIEWS)
                    );
                  }
                }
              }}
              isViewPanelPinned={isViewpanelPinned}
              onViewChange={handleViewSelectionClick}
              onViewDelete={(viewId: number) => {
                const selectedView = orderViews.find(
                  (view) => view.viewId === viewId
                );
                if ((orderStatus !== OrderStatus.SelfMeasured) && selectedView?.isDefault) {
                  const baseView = orderViews.find(
                    (view) => view.isBaseView === true
                  )
                  handleSetDefaultView(baseView);
                }
                if (selectedView) handleDeleteView(selectedView);
              }}
              onSetDefault={(viewId: number) => {
                const selectedView = orderViews.find(
                  (view) => view.viewId === viewId
                );
                handleSetDefaultView(selectedView);
              }}
              selectedViewIndex={currentView.viewId}
              defaultId={orderViews.find((view) => view.isDefault)?.viewId}
              onRenameView={(viewId: number, newName: string) => {
                const selectedView = orderViews.find(
                  (view) => view.viewId === viewId
                );
                if (selectedView) performEditViewName(selectedView, newName);
              }}
              orderStatus={getPlasmicOrderStatus(orderStatus)}
              userRole={userRole}
            />
          </div>
        </div>
      </CSSTransition>
      {deleteViewModal}
      {createNewViewModal()}
      {copyViewModal}
    </>,
    document.body
  );
};

export default ViewSelectionPanel;
